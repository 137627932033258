<template>
  <div>
    <Breadcrumbs
    :title="mainObject.spName + ' - ' + mainObject.sName"
    class="text-blue"
    :subtitle="mainObject.category"
    />

   <b-row>
        <b-col class="m-0">
          <b-card  class="custom-modal-card-2  custom-font-30 text-blue  ">
            <b-row style="" class="m-5">
              <b-col class="">
                <span><b>Home Page</b></span>
                <b-img
                  thumbnail
                  fluid
                  class="img_custom_style"
                  style="max-height: 410px;"
                  :src="getImageBanner(mainObject._id)"
                  alt="Banner"
                  @click="openImageModal(mainObject._id, 'banner')"
                  @error="$event.target.src = `../no_image.png`;"
                ></b-img>
              </b-col>

              <b-col class="col-md-6">
                <span><b>Advertisement</b></span>
                <b-img
                  thumbnail
                  fluid
                  class="img_custom_style"
                  style="max-height: 405px;"
                  :src="getImageAdd(mainObject._id)"
                  alt="Ad"
                  @click="openImageModal(mainObject._id, 'add')"
                  @error="$event.target.src = `../no_image.png`;"
                ></b-img>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col class="col-md-7 m-0 pd-2">
          <ad-details-vue :mainObject="mainObject"></ad-details-vue>
        </b-col>
      </b-row>
       
       
      <b-row class="p-2">
        <b-card class="custom-modal-card-2 mt-2" > 
          <template #header>
            <h4 class="m-0 custom-font text-blue"><b>Landings</b></h4>
          </template>
          
          <b-row v-if="activeSection === 'landings'" class="m-0">
            <b-col>
              <b-row class="">
                <b-col class="" v-for="(image, index) in mainObject.landingImgUrl" :key="index">
                  <b-img 
                    thumbnail 
                    fluid 
                    :src="image" 
                    alt="landing" 
                    :class="{'img-custom-multiple': mainObject.landingImgUrl.length > 3, 'img_custom_style-2': true}"
                    @click="openImageModal(image, 'landing')" 
                    @error="$event.target.src = `../no_image.png`;"
                  ></b-img>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols = "6" class="m-0 custom-font-16"> 
                <insight-list :sid="mainObject.sid" :sdate="sdate" :edate="edate"></insight-list>
            </b-col>
          </b-row>
        </b-card>   
      </b-row>

      
      <!-- <b-row v-if="activeSection === 'insight'" class="p-2 mb-3 mt-3">
      
      </b-row> -->
      <!-- <b-card title="Landings">
            <b-row>
                <b-col md="3" v-for="(image, index) in mainObject.landingImgUrl" :key="index">
                  <img :src="image" alt="landing" class="img_custom_style" @click="openImageModal(image, 'landing')" @error="$event.target.src=(`../no_image.png`);">
                </b-col>
            </b-row>
      </b-card>  -->
     <div class="row p-2">
            <b-card  class="custom-modal-card mt-2">
              <b-tabs pills content-class="mt-3" nav-class="h6 font-weight-bold" >
                <b-tab title="Hosts" active>
                  <b-card-text>
                    <ul class="custom-font-16 custom-list">
                      <li v-for="(item, index) in mainObject.hosts" :key="index" class="list-item">
                        {{ item }}
                      </li>
                    </ul>
                  </b-card-text>
                </b-tab>
                <b-tab title="Urls">
                  <b-card-text>
                    <ul class="custom-font-16 custom-list">
                      <li v-for="(item, index) in mainObject.urlChain" :key="index" class="list-item">
                        {{ item }}
                      </li>
                    </ul>
                  </b-card-text>
                </b-tab>
                <b-tab title="Logs">
                  <b-card-text>
                    <ul class="custom-font-16 custom-list">
                      <li v-for="(item, index) in mainObject.logs" :key="index" class="list-item">
                        {{ item }}
                      </li>
                    </ul>
                  </b-card-text>
                </b-tab>
                <b-tab title="Comments">
                  <b-card-text>
                    <!-- <b-row class="mt-2">
                      <b-col class="custom-font-16 text-primary" style="cursor: pointer;">
                       <span @click="getComments(mainObject._id)"> Click Here to show all commnets </span>
                      </b-col>
                    </b-row> -->
                    <b-row>
                      <b-col>
                        <div class="col-md-3" v-if="load_commnet">
                            <div class="loader-box">
                              <div class="loader-15"></div>
                            </div>
                        </div>
                        <b-table
                          v-else
                          stacked="md"
                          :items="commentList"
                          :fields="comment_fields"
                          striped
                          thead-class="d-none"
                          v-model="currentComment"
                        
                        >
                        <template #cell(_id)="row">
                            {{ row.item.comment }} – 
                            <span class="f-12 text-muted">Commented by </span>
                            <span class="text-primary" v-if="row.item.role == 'manager'">Manager</span>
                            <span class="text-primary" v-else>{{ row.item.username?row.item.username:row.item.useremail }}</span>
                        </template>
                        <template #cell(show_details)="row">
                    
                          <button
                            v-if="row.item.useremail === userinfo.email || userinfo.role === 'manager'"
                            class="btn btn-outline-info btn-xs mr-2"
                            @click="toggleDetails(row)">
                            {{ row.detailsShowing ? "Cancel" : "Edit" }}
                          </button>
                          <button
                            class="btn btn-outline-danger btn-xs mr-2"
                            v-if="row.item.useremail === userinfo.email || userinfo.role === 'manager'"
                            @click="DeleteComment(row.item)" variant='danger'>
                            Delete
                          </button>
                        </template>
                        <template #row-details="row">
                          <b-card bg-variant="light">
                            <b-form-textarea
                                id="commnets"
                                placeholder="Comment..."
                                rows="1"
                                max-rows="5"
                                v-model="row.item.comment"
                                >
                                </b-form-textarea>
                            <b-button size="sm" @click="editComment(row.item)" variant='info' class="mt-2">
                              Edit
                            </b-button>
                          </b-card>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                      <b-form-textarea
                      id="commnets"
                      placeholder="Comment..."
                      rows="3"
                      max-rows="6"
                      v-model="comment_text"
                      >
                      </b-form-textarea>
                    </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <b-button variant="success" @click.prevent="AddCommnet(mainObject._id)">Add Comment</b-button>
                      </b-col>
                    </b-row>

                  </b-card-text>
                  </b-tab
                >
                <b-tab title="Share"
                  >
                  <b-row>
                    <b-col cols="12">
                      <textarea class="col-md-12" name="" id="" rows="20" :value="mailToLink(public_service_url)"></textarea>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="link_txt" @click="CopyShareUrl(mailToLink(public_service_url))">Click here to copy...</b-col>
                    <b-col class="text-right">
                      <a :href="SendMail(mailToLink(public_service_url))">Share...</a> </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
    </div>
  </template>
  
  <script>
  import TranscationService from "../../../services/TranscationService";
  import NetworkTypeService from '../../../services/NetworkTypeService'
  import InsightList from './../Insight/InsightList.vue'
  
  import config from "../../../services/globalLit";
import AdDetailsVue from '../../components/AdDetails.vue';
  
  export default {
    components: {
      InsightList,
      AdDetailsVue
    },
    data() {
      return {
        sdate: this.$route.query.sdate,
        edate: this.$route.query.edate,
        comment_fields: ["_id",  "show_details"],
        currentComment: [],
        commentList:[],
        mainObject: {
          adDetailsTable: []
        },
        
        adDetailsTable2: [
            { trxId: '66bee23438ac1b7ba884c74b', trx_name: 'Test provider - testservice', netOp: 'Macdonald', date: '2021-02-11' }
        ],
      
        showModal: "",
  
        tableKeys: {
          bannerFoundUrl: "Banner Found URL",
          adUrl: "Ad URL",
          finalUrl: "Final URL",
          netType: "Network|OP", //1: wifi 2: op1 3: op2
          channel: "Channel",
          paymentInfo: "Payment Info",
          pricePointInfo: "Pricepoint Info",
          createdAt: "Date",
        },
        fields: ["key", "value"],
        adDetailsTable: [{ key: "action", value: "action" }],
        modalimageurl: "",
        show_all_commnets: false,
        comment_text: "",
        public_service_url:'',
        userinfo:{},
        load_commnet:false,
        networkTypeArray : [],
        activeSection: '',
      };
    },
    computed: {
      isMobile() {
        return window.innerWidth <= 768;
      }
    },
    mounted() {
      const trxid = this.$route.query.trxid;
      const section = this.$route.query.section || 'landings'; 

      if (trxid) {
        this.mainObject = {
          adDetailsTable: [],

        };
        // console.log('trxid from query params:', trxid);

        // Set the trxid and initialize the data
        this.data = { trxid: trxid };
        this.init();
      }

      this.activeSection = section;
    },
    beforeDestroy() {
      console.log('distroyed openTrx')
      this.$root.$off("openTrx");
    },
    methods: {
      redirectToInsight(trxid) {
        const url = this.$router.resolve({
          name: 'ViewTrx',
          query: {
            trxid: trxid,
            section: 'insight', 
          }
        }).href;

        window.open(url, '_blank');
      },
      async editComment(data){
        this.load_commnet = true;
        let payload = {'cid':data._id, 'updateObj':{comment:data.comment}}
        let response = await TranscationService.editComment(payload);
        this.getComments(data.trxid);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        this.load_commnet = false;
      },
     async DeleteComment(data){
      this.load_commnet = true;
      var payload = {'cid':data._id}
      if(confirm("Are you sure you want to delete this comment?")){
        let response = await TranscationService.deleteComment(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        this.getComments(data.trxid);
      }
      this.load_commnet = false;
      },
      async getComments(id){
        this.load_commnet = true;
        let response = await TranscationService.getComment(id);
        if(response.result){
          this.commentList = response.data
        }
        this.load_commnet = false;
      },
      async AddCommnet(id){
        this.load_commnet = true;
        if(this.comment_text == ""){
          this.$toasted.show("Please enter comment", {theme: 'outline',position: "bottom-center", type: "error", duration: 2000});
          return;
        }
        var payload = {"trxid":id,"comment":this.comment_text}
        let response = await TranscationService.addCommnet(payload);
        this.getComments(id);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        this.comment_text = "";
        this.load_commnet = false;
      },
      CopyShareUrl(text){
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = text;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show("Copied", {theme: 'outline',position: "bottom-center", type: "success", duration: 2000});
      },
     async ExportTrx(id){
        let url = config.exportTrnx+`${id}`
        const a = document.createElement('a');
        a.href = url;
        a.download = 'transaction.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      openImageModal(data, type) {
        this.modalimageurl = "";
        switch(type){
          case 'banner':
            this.modalimageurl = this.getImageBanner(data)
            break;
          case 'add':
            this.modalimageurl = this.getImageAdd(data)
            break;
          case 'landing':
            this.modalimageurl = data
            break;
        }
        // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
        const popupWindow = window.open('', '_blank', `width=${screen.width},height=${screen.height}`);
        popupWindow.document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
        popupWindow.document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
          popupWindow.close();
        }});
      },
      getImageLanding(id){
        return config.getLandingImages+`/${id}`
      },
      getImageBanner(id){
        return config.getBannerImages+`/${id}`
      },
      getImageAdd(id){
        return config.getAddImages+`/${id}`
      },
      arrayBufferToBase64(buffer) {
        var binary = "";
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return binary;
      },
      async init() {
        console.log("init call")
        let urlResponse = await this.getPublicShareUrl(this.data.trxid)
        await this.getNetworkType();
        let payload = {
          sid: this.data._id,
          trxid: this.data.trxid
        };
        let response = await TranscationService.trxDetail(payload);
        if (response.result) {
          
          this.mainObject = response.data;
          this.mainObject.adDetailsTable = []
          
          this.mainObject.category = config.categorylist.find(e => e.id == this.mainObject.category).name;
         
          let trxKey = Object.keys(this.mainObject)
  
          for (let i = 0; i < trxKey.length; i++) {
            if (this.tableKeys[trxKey[i]]) {
              let rec = {
                key: this.tableKeys[trxKey[i]],
                value: this.mainObject[trxKey[i]],
              };
              if (rec.key == "Channel") {
                rec.value = config.channelTypeArray.find(
                  (e) => e.id == rec.value
                ).name;
              }
              if (rec.key == "Network|OP") {
                rec.value = this.networkTypeArray.find(
                  (e) => e.id == rec.value
                ).name;
              }
              this.mainObject.adDetailsTable.push(rec);
            }
          }
          this.mainObject.adDetailsTable.push({ key: "Export", value: "Export" });
        }
        this.getComments(this.mainObject._id)
        this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
      },
      async getPublicShareUrl(trxId) {
        let response = await TranscationService.getPublicShareUrl(trxId);
        if (response.result) {
           this.public_service_url =  response.data;
        }
      },
      async getNetworkType() {
        let response = await NetworkTypeService.getAllNetworkType();
        let networkTypeArray = [];
        for (const [key, value] of Object.entries(response.data)) {
          networkTypeArray.push({ id: key, name: value });
        }
        this.networkTypeArray = networkTypeArray;
      },
      toggleDetails(row) {
        if (row.detailsShowing) {
          row.toggleDetails()
        } else {
          this.currentComment.forEach(item => {
            this.$set(item, '_showDetails', false)
          })
          this.$nextTick(() => {
            row.toggleDetails()
          })
        }
      },
      mailToLink(url) {
        let body = `
Dear Marketing Team,

  I am writing to bring to your attention an issue related to a recent marketing that was conducted for the brand.

  As a concerned party, I would like to request that you review this matter and take the necessary corrective action to ensure that your advertising and
  marketing practices are fully compliant with all relevant regulatory requirements.

  For your reference, here is the link to the advertising page that was used during the test:
  <a href="${url}">${url}</a>

  If you require further information or clarification on this matter, please do not hesitate to reach out to me.

  Thank you for your prompt attention to this issue.

Sincerely,
${this.userinfo.username}
${this.userinfo.email}
`.replace(/<[^>]*>/g, ''); 
        return body
      },
      SendMail(data){
        let from = this.userinfo.email
        let subject = 'Concern regarding recent marketing'
        let body = data 
        let mailtoLink = 'mailto:?subject='+encodeURIComponent(subject)+'&body='+encodeURIComponent(body)+'&from='+from
          // Set the Content-Type header to "text/html"
          mailtoLink += '&Content-Type=text/html';
          // Add the "from" parameter to the mailto link if the "from" address is specified
          if (from) {
            mailtoLink += '&from=' + encodeURIComponent(from);
          }
        return mailtoLink
      }
    },
  };
  </script>
  
<style scoped>

  .hidden_header {
    display: none;
  }
  div {
    margin: auto;
    width: 100%;
  }
  .img_custom_style {
    min-height: 200px !important;
    min-width: 100px;
    max-height: 430px !important;
    max-width: 100%;
  }

  .img_custom_style-2 {
    min-height: 180px;
    min-width: 100px;
    max-height: 420px;
    max-width: 100%;
  }

  .img-custom-multiple {
    min-width: 80px;
    max-width: 100px;
  }

  .img_custom_style:hover,
  .img_custom_style-2:hover {
    cursor: pointer;
    border: 2px solid #274AA6;
  }

  .custom-modal-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    border-radius: 10px;
    word-wrap: break-word;
  }


  .custom-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .list-item {
    position: relative;
    padding: 10px 0;
  }

  .list-item:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px dashed #000;
    margin-top: 5px;
    width: 30%;
  }

  .custom-head-text{
    margin-left: 2rem;
  }

  hr {
    margin-top: 0;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
    border-top: 1px solid #909090;
  }

  .copyurl-btn-container{
    display: flex;
    align-items: center;
    margin-left: 1.2rem;
  }


  @media (max-width: 767px) {

    .custom-head-text, hr {
      margin-left: 0.5rem;
    }

    .custom-modal-card:last-child {
      width: 95%;
      padding: 0.5rem;
    }

    .img_custom_style-2 {
      margin: 10px 0px 10px 0px;
      max-height: 120px;
      max-width: 50px;
    }

    .img-custom-multiple {
      max-height: 120px;
      max-width: 50px;
    }

    .list-item:not(:last-child)::after {
      width: 100%;
    }

    .copyurl-btn-container {
      margin-left: 0;
      margin-top: 0.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
    }
    
    .copyurl-btn-container button {
      margin-top: 0.5rem;
    }
}

</style>
<style>
  .custom-modal-card .nav-pills li .nav-link.active,
  .custom-modal-card .nav-pills .show > .nav-link {
    background-color: #274AA6 !important;
    color: white !important;
    font-size: 18px;
  }

  .custom-modal-card .nav-item a{
    color: #274AA6 !important;
    font-size: 18px;
  }

  .card-header-pills {
      margin-left: 0;
  }


  @media (max-width: 767px) {
    .custom-modal-card .nav-pills li .nav-link.active,
    .custom-modal-card .nav-pills .show > .nav-link,
    .custom-modal-card .nav-item a{
      font-size: 16px !important;
      padding: 10px !important;
    }
    .nav {
      display: flex !important;
      flex-wrap: nowrap !important;
      overflow-x: auto !important;
    }

    .adDetail-table tr {
      -webkit-box-shadow: 0px;
      box-shadow: none;
    }

    .adDetail-table td {
      padding: 0.5rem 0.75rem;
    }

    .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before  {
      text-align: left;
    }

    /* .insight-service-cont table {
      text-align: left !important;
    } */
  }
</style>